export default class MenuToggle {
    static init() {
        const $body = document.querySelector("body")
        const $toggle = document.querySelector(".t-MenuToggle")

        if (!$toggle) {
            return
        }

        $toggle.addEventListener("click", (e) => {
            e.preventDefault()

            if ($body.classList.contains("-no-scroll")) {
                $body.classList.remove("-no-scroll", "-show-menu")
            } else {
                this.scrollTop(() => {
                    $body.classList.add("-no-scroll", "-show-menu")
                })
            }
        })
    }

    static scrollTop(callback) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop

        if (scrollTop) {
            window.scrollBy({ top: -1000, left: 0, behavior: "smooth" })
            window.addEventListener("scroll", callback, { once: true })
        } else {
            callback()
        }
    }
}
