import "lightgallery.js"
import "lg-autoplay.js"
import "lg-thumbnail.js"
import "lg-fullscreen.js"
import "picturefill"

let trip = {}

trip.init = () => {
    trip.initGallery()
    trip.initMapLightbox()
    trip.bindDatesBooking()
}

trip.initGallery = () => {
    let $gallery = document.querySelector(".c-Gallery")

    if (!$gallery) {
        return
    }

    lightGallery($gallery, {
        selector: ".-js-gallery-item",
        download: false,
        fullScreen: true,
        thumbnail: true,
    })
}

trip.initMapLightbox = () => {
    let $map = document.querySelector(".-js-trip-map")

    if (!$map) {
        return
    }

    $map.addEventListener("click", function(e) {
        e.preventDefault()

        lightGallery($map, {
            dynamic: true,
            download: false,
            dynamicEl: [
                {
                    src: this.getAttribute("data-src"),
                    srcset: this.getAttribute("data-srcset"),
                    sizes: this.getAttribute("data-width") + "px",
                },
            ],
        })
    })
}

trip.bindDatesBooking = () => {
    let $links = document.querySelectorAll(".-js-trip-booking")
    let $dateField = document.querySelector(".-js-trip-booking-date")

    if (!$dateField) {
        return
    }

    for (let $link of $links) {
        $link.addEventListener("click", function() {
            let dateId = this.getAttribute("data-date")

            if (!dateId) {
                return
            }

            $dateField.value = dateId
        })
    }
}

export default trip
