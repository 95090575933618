import { throttle } from "@/utils/utils"

const base = {}

base.init = () => {
    base.fixVhUnits()
}

base.fixVhUnits = () => {
    const fix = () => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    window.addEventListener("resize", throttle(fix, 200))
    fix()
}

export default base
