import { scrollTo } from "@/utils/utils"

class AccordionGroup {
    constructor($accordionGroup) {
        this.$accordionGroup = $accordionGroup
        this.$inputs = this.$accordionGroup.querySelectorAll(".-js-accordion-group-toggle")

        for (let $input of this.$inputs) {
            this.onToggle($input)
        }
    }

    onToggle($input) {
        $input.addEventListener("change", (e) => {
            if (!e.target.checked) {
                return
            }

            for (let $el of this.$inputs) {
                if ($el == $input) {
                    continue
                }

                $el.checked = false
            }

            scrollTo($input.parentNode, 100)
        })
    }
}

let init = () => {
    let $accordions = document.querySelectorAll(".-js-accordion-group")

    for (let $accordion of $accordions) {
        new AccordionGroup($accordion)
    }
}

export default { init }
