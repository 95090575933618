import { scrollTo, throttle } from "@/utils/utils"

class Tabs {
    constructor($tabs) {
        this.$tabs = $tabs
        this.initialTab()
        this.bindTabLinks()
        this.handlePreviousPage()
        this.handleTabsPosition()
    }

    initialTab() {
        let tab = this.getTabName()

        if (!tab) {
            return
        }

        let $tab = this.$tabs.querySelector(`input.-js-tab[data-name="${tab}"]`)

        if (!$tab) {
            return
        }

        $tab.checked = true

        this.scrollToTabs()
    }

    bindTabLinks() {
        let $links = document.querySelectorAll(".-js-tab-link")

        if (!$links) {
            return
        }

        for (let $link of $links) {
            $link.addEventListener("click", () => {
                let tab = $link.getAttribute("for")
                let $tab = this.$tabs.querySelector(`input.-${tab}`)
                let name = $tab.getAttribute("data-name")

                if (!tab || !$tab) {
                    return
                }

                history.pushState({ tab: name }, null, this.getTabUrl(name))

                this.scrollToTabs()
            })
        }
    }

    handlePreviousPage() {
        window.addEventListener("popstate", (e) => {
            let name = e.state && e.state.hasOwnProperty("tab") ? e.state.tab : this.getTabName()

            let $tab = name ? this.$tabs.querySelector(`input.-js-tab[data-name="${name}"]`) : this.$tabs.querySelector(`input.-js-tab:first-child`)
            console.log(e, name, $tab)

            if (!$tab) {
                return
            }

            $tab.checked = true

            this.scrollToTabs()
        })
    }

    handleTabsPosition() {
        let $menu = this.$tabs.querySelector(".-js-tabs-menu")

        if (!$menu) {
            return
        }

        let offset = $menu.offsetTop
        let onScroll = () => {
            if (window.pageYOffset >= offset) {
                this.$tabs.classList.add("-fixed")
                this.$tabs.style.paddingTop = `${$menu.offsetHeight}px`
            } else {
                this.$tabs.classList.remove("-fixed")
                this.$tabs.style.paddingTop = 0
                offset = $menu.offsetTop
            }
        }

        window.addEventListener("scroll", throttle(onScroll, 50))
    }

    getTabName() {
        let urlParams = new URLSearchParams(window.location.search)

        return urlParams.get("onglet")
    }

    getTabUrl(name) {
        let urlParams = new URLSearchParams(window.location.search)

        urlParams.set("onglet", name)

        if (!name) {
            urlParams.delete("onglet")
        }

        return name ? `?${urlParams.toString()}` : window.location.pathname
    }

    scrollToTabs() {
        setTimeout(() => {
            scrollTo(this.$tabs)
        }, 1)
    }
}

let init = () => {
    let $tabs = document.querySelectorAll(".-js-tabs")

    for (let $tab of $tabs) {
        new Tabs($tab)
    }
}

export default { init }
