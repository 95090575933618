import SlimSelect from "slim-select"

let init = () => {
    let $selectMenus = document.querySelectorAll(".-js-select-menu:not(.-js-upgraded)")

    for (let $selectMenu of $selectMenus) {
        let $select = $selectMenu.querySelector(".-js-select-menu-input")

        if (!$select) {
            continue
        }

        let options = {
            select: $select,
            searchPlaceholder: "Rechercher",
            placeholder: true,
            searchText: "Aucun résultat",
            allowDeselectOption: true,
            searchFocus: false,
            showSearch: $select.getAttribute("data-show-search") !== "false",
        }

        $selectMenu.classList.add("-js-upgraded")
        new SlimSelect(options)
    }
}

export default { init }
