;(function() {
    let $filters = document.querySelector(".-js-search-filters")

    if (!$filters) {
        return
    }

    let $submit = $filters.querySelector(".-js-search-submit")
    let $country = $filters.querySelector(".-js-search-country select")
    let $sortBy = $filters.querySelector(".-js-search-sort-by select")
    let isHome = $filters.classList.contains("-home")

    if (!$submit || !$country) {
        return
    }

    $submit.addEventListener("click", function(e) {
        e.preventDefault()

        if (!$country.value) {
            return
        }

        let href = `${baseurl}/pays/${$country.value}`

        if (!isHome) {
            href += "#recherche"
        }

        let url = new URL(href)

        if ($sortBy && $sortBy.value) {
            url.searchParams.append("trie", $sortBy.value)
        }

        window.location.href = url
    })
})()
