;(function() {
    var sliderInterval = 7000

    const $slider = document.querySelector(".-js-heading-slider")

    if (!$slider) {
        return
    }

    const $items = $slider.querySelectorAll(".c-HeadingSlider__item")
    const count = $items.length
    var i = 0

    if (count < 2) {
        return
    }

    $items[0].classList.add("-active")
    $slider.classList.add("-js-upgraded")

    setInterval(function() {
        const prev = i
        i++

        if (i >= count) {
            i = 0
        }

        $items[i].classList.add("-active")
        $items[prev].classList.remove("-active")
    }, sliderInterval)

    let $more = $slider.querySelector(".-js-heading-slider-more")

    if (!$more) {
        return
    }

    $more.addEventListener("click", (e) => {
        e.preventDefault()

        window.scroll({
            behavior: "smooth",
            left: 0,
            top: $slider.offsetTop + $slider.offsetHeight,
        })
    })
})()
