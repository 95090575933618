import "./index.styl"

import { Ripple, Button, FormRepeater, OptionsGroup, Checkbox, Textfield, Select } from "@framework"
import MenuToggle from "./component/menu/menu.js"
import SelectMenu from "./component/select-menu/select-menu.js"
import Tabs from "./component/tabs/tabs.js"
import AccordionGroup from "./component/accordion-group/accordion-group.js"
import Base from "./template/base/base.js"
import Trip from "./template/trip/trip.js"
import Contact from "./template/contact/contact.js"
import "./component/heading-slider/heading-slider.js"
import "./component/search/search.js"
import "./template/trip/trip.js"
import "youtube-background"

// Init before DOMContentLoaded
Base.init()
Contact.init()

// Init after DDOMContentLoadedOM
document.addEventListener("DOMContentLoaded", () => {
    Button.init()
    Ripple.init()
    Checkbox.init()
    FormRepeater.init()
    OptionsGroup.init()
    Textfield.init()
    Select.init()
    MenuToggle.init()
    SelectMenu.init()
    Tabs.init()
    AccordionGroup.init()
    Trip.init()
    new VideoBackgrounds("[data-vbg]")
})
